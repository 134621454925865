export const FORBIDDEN = '403';
export const HOME = 'index';
export const LOGIN = 'auth-login';

export const CAMPAIGNS = {
  INDEX: 'campaigns',
};

export const PUBLISHER = {
  IDENTITIES: 'publishers-identities',
};
